export const SEND_EMAIL = "https://us-central1-elite-views.cloudfunctions.net/sendMail";
export const EMAIL_SUBJECT = "Contact Request";
export const EMAIL_BODY = (data) => {
    let body = `<h4>The following person is trying to reach you: </h4>`;
    data.forEach(item => {
        body = body + 
              `<p><strong>${item.name}:</strong>  ${item.value} <p>`;
    });
    return body;
}

