import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Typography, Box, Grid, Toolbar, Container, useMediaQuery } from "@material-ui/core";
import ParallaxImage from '../../components/Parallax/Parallax';
import home from "../../assets/images/home.jpg";
import home_sm from "../../assets/images/home_sm.jpg";
import feature_logo from "../../assets/logo/feature_logo.png";
import { NAVIGATION_LINKS } from '../../const';

const useStyles = makeStyles((theme) => ({
    root: {
        position: "relative"
    },
    featureImage: {
        width: "90%",
        height: "100%"
    },
    parallaxContentWrapper: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%'
    },
    parallaxContent: {
        position: "absolute",
        zIndex: 1,
        height: "100%"
    },
    overlay: {
        background: theme.palette.primary.light,
        opacity: 0.9,
        width: '100%',
        height: '100%',
        zIndex: 0
    },
    actionButton: {
        margin: '0 auto',
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        zIndex: theme.zIndex.speedDial,
    },
    arrow: {
        width: '100%',
        bottom: 0,
        position: 'absolute',
        textAlign: 'center',
        zIndex:1,
        paddingTop: theme.spacing(5),
        paddingBottom:theme.spacing(2)
    },
    bodyText: {
        color: theme.palette.primary.contrastText
    }
}));
export default function Home(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("xs"));

    return (
        <section id={NAVIGATION_LINKS.HOME} className={classes.root}>
            <ParallaxImage image={isMobile ? home_sm :home} height={"100vh"}>
                <Grid container className={`${classes.parallaxContentWrapper}`}>
                    <Grid container alignItems="center" item xs={12} sm={6} className={classes.parallaxContent}>
                        <Container>
                            <Grid container direction="column" justify="center">
                                <Grid item xs={12}>
                                    <Toolbar />
                                </Grid>
                                <Grid item xs={12}>
                                    <img className={classes.featureImage} src={feature_logo} alt="feauture logo" />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box m={3} textAlign="center">
                                        <Typography variant="overline" color="secondary" style={{ fontWeight: "bolder" }} align="center" paragraph={true}>We understand your unique needs</Typography>
                                        <Typography className={classes.bodyText} variant="caption" paragraph={true}>
                                            Rising overhead, declining 3rd party reimbursement, and the mounting confusion of new healthcare regulations are challenging independent providers like you. When stress and associated headaches are removed from your clinic, you have a more focused practice and/or so you are better positioned to prosper. Let you focus and your core business and Elite Views, LLC will take care of the rest.
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Container>
                        {/* <div className={classes.arrow}>
                            <IconButton color="secondary" onClick={navigate}>
                                <KeyboardArrowDownIcon style={{fontSize:100}}/>
                            </IconButton>
                        </div> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <div className={classes.overlay} />
                    </Grid>
                </Grid>
            </ParallaxImage>
        </section >
    );
}
