import React from 'react';
import { AppBar, Grid, Toolbar, IconButton, makeStyles, useTheme, Fade, useMediaQuery, Slide, Drawer, Button, Popper, List, ListItem, ListItemText, Paper, Grow, ClickAwayListener, Accordion, AccordionSummary, Typography, AccordionDetails } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import logo from "../../assets/logo/header_logo_text.png";
import { useHistory } from "react-router-dom";
import { NAVIGATION_LINKS_LIST } from '../../const';
import CloseIcon from '@material-ui/icons/Close';
import { Rotate, Flip } from "react-reveal";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 200,
  },
  primaryColor: {
    backgroundColor: theme.palette.primary.main,
  },
  transparent: {
    backgroundColor: 'rgb(0 0 0 / 0%)'
  },
  iconButton: {
    position: "relative"
  },
  logo: {
    padding: theme.spacing(1),
    width: "30%"
  },
  hide: {
    display: 'none',
  },
  grow: {
    flexGrow: 1,
  },
  profilePic: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    width: 100,
    height: 100,
    borderRadius: '100%',
    margin: theme.spacing(1),
  },
  center: {
    margin: "auto 0",
    whiteSpace: "nowrap"
  },
  flex: {
    display: "flex"
  },
  drawer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end"
  },
  tabSelected: {
    backgroundImage: `linear-gradient(to right, ${theme.palette.secondary.main}, rgba(255,0,0,0) 50%)`
  },
  toolbarRoot: {
    display: "flex",
    justifyContent: "flex-end"
  },
  indicator: {
    left: 0,
    width: theme.spacing(1),
    marginRight: theme.spacing(1),
    color: theme.palette.secondary.light
  },
  drawerPaper: {
    height: "fit-content",
  },
  menuIcons: {
    position: "absolute",
    top: 0
  },
  menuButton: {
    color: theme.palette.primary.contrastText,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    '&:hover': {
      color: theme.palette.primary.light,
      fontWeight: "bold"
    }
  },
  arrow: {
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
      content: '""',
      margin: 'auto',
      display: 'block',
      width: 0,
      height: 0,
      borderStyle: 'solid',
    }
  },
  popper: {
    zIndex: theme.zIndex.drawer + 201,
    '& $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.9em',
      width: '3em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
      },
    }
  }
}));





export default function Header(props) {
  let history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [mobileMenuState, setMobileMenuState] = React.useState(false);
  const [popperAnchorRef, setPopperAncherRef] = React.useState(null);
  const [arrowRef, setArrowRef] = React.useState(null);
  const [openAccordion, setOpenAccordion] = React.useState(false);
  const openPopper = Boolean(popperAnchorRef);
  const handleChange = (href) => {
    history.push(href);
    setMobileMenuState(false);
  };

  const sideMenu = (
    <Drawer
      anchor="right"
      open={mobileMenuState && isMobile}
      onClose={() => setMobileMenuState(false)}
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <Toolbar />
      <div className={classes.drawer}>
        <Grid container direction="column">
          {NAVIGATION_LINKS_LIST.map((link) => {
            return (
              <Grid item key={link.id}>
                {link.options.length > 1 ? (
                  <Accordion elevation={0} expanded={openAccordion} onClick={() => setOpenAccordion(!openAccordion)} onMouseEnter={() => setOpenAccordion(true)}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="button">{link.label}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <List>
                        {link.options.map(option => {
                          return (
                            <ListItem key={option.id} button onClick={() => handleChange(option.href)}>
                              <ListItemText primary={option.label}></ListItemText>
                            </ListItem>
                          )
                        })}
                      </List>
                    </AccordionDetails>
                  </Accordion>
                ) :
                  (
                    <List>
                    {link.options.map(option => {
                      return (
                        <ListItem key={option.id} button onClick={() => handleChange(option.href)}>
                          <ListItemText primary={option.label}></ListItemText>
                        </ListItem>
                      )
                    })}
                  </List>
                  )}
              </Grid>
            )
          })}
        </Grid>
      </div>
    </Drawer>
  );
  const toolbar = (isTransparentNavBar) => (
    <Toolbar id="nav-bar" className={classes.toolbarRoot}>
      {!isTransparentNavBar && (
        <React.Fragment>
          <img className={classes.logo} src={logo} alt="Elite view logo" />
        </React.Fragment>
      )}
      <div className={classes.grow} />
      <Fade in={!isMobile} unmountOnExit>
        <Grid container justify="flex-end">
          <Flip top left cascade delay={500}>
            {NAVIGATION_LINKS_LIST.map((link) => {
              return (
                <Grid item key={link.id}>
                  {link.options.length > 1 ? (
                    <ClickAwayListener onClickAway={() => setPopperAncherRef(null)}>
                      <div onMouseLeave={() => setPopperAncherRef(null)}>
                        <Button
                          className={classes.menuButton}
                          variant="text"
                          endIcon={<ArrowDropDownIcon />}
                          onMouseEnter={(event) => setPopperAncherRef(event.currentTarget)}
                          onClick={(event) => setPopperAncherRef(event.currentTarget)}
                          ref={popperAnchorRef}>
                          {link.label}
                        </Button>
                        <Popper
                          className={classes.popper}
                          anchorEl={popperAnchorRef}
                          open={openPopper}
                          placement="bottom"
                          disablePortal={false}
                          transition
                          modifiers={{
                            arrow: {
                              enabled: true,
                              element: arrowRef,
                            }
                          }}>
                          {({ TransitionProps }) => (
                            <Grow
                              {...TransitionProps}
                              style={{ transformOrigin: 'center top' }}
                            >
                              <div>
                                <span className={classes.arrow} ref={setArrowRef} />
                                <Paper elevation={3}>
                                  <List>
                                    {link.options.map(option => {
                                      return (
                                        <ListItem key={option.id} button onClick={() => handleChange(option.href)}>
                                          <ListItemText primary={option.label}></ListItemText>
                                        </ListItem>
                                      )
                                    })}
                                  </List>
                                </Paper>
                              </div>
                            </Grow>
                          )}
                        </Popper>
                      </div>
                    </ClickAwayListener>
                  ) :
                    (
                      <Button className={classes.menuButton} variant="text" onClick={() => handleChange(link.options[0].href)}>
                        {link.options[0].label}
                      </Button>
                    )}
                </Grid>
              )
            })}
          </Flip>
        </Grid>
      </Fade>
      <Fade in={isMobile} mountOnEnter unmountOnExit>
        <IconButton
          edge="start"
          className={classes.iconButton}
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMobileMenuState(!mobileMenuState)}
        >
          <Rotate opposite when={!mobileMenuState}>
            <div className={classes.menuIcons}>
              <MenuIcon />
            </div>
          </Rotate>
          <Rotate opposite when={mobileMenuState}>
            <div className={classes.menuIcons}>
              <CloseIcon />
            </div>
          </Rotate>
        </IconButton>
      </Fade>
    </Toolbar>
  )

  const regularNavBar = (
    <Slide in={true} direction="down">
      <AppBar className={`${classes.appBar} ${classes.primaryColor}`} elevation={4}>
        {toolbar(false)}
      </AppBar>
    </Slide>
  )
  return (
    <React.Fragment>
      {regularNavBar}
      {sideMenu}
    </React.Fragment>
  )
}