import React from "react";
import { makeStyles, AppBar, Toolbar, Typography, List, ListSubheader, ListItem, ListItemText, Hidden } from '@material-ui/core';
import feature_logo from "../../assets/logo/feature_logo.png";
import { NAVIGATION_LINKS_LIST } from "../../const";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: theme.palette.primary.light
    },
    grow: {
        flexGrow: 1,
    },
    image: {
        width: "20%",
        height: "20%",
        marginRight: theme.spacing(3)
    },
    subHeader: {
        color: theme.palette.common.white,
        lineHeight: 0
    }
}));
function Footer(props) {
    const classes = useStyles();
    const history = useHistory();
    const handleNavigation = (href) => {
        history.push(href);
    }
    return (
        <footer >
            <AppBar position="static" className={classes.root}>
                <Toolbar>
                    <Hidden smDown>
                        <img src={feature_logo} className={classes.image} alt="logo" />
                    </Hidden>
                    <List>
                        {NAVIGATION_LINKS_LIST.map((link, index) => {
                            return (
                                <List key={"list_link_" + index}
                                    dense
                                    subheader={link.options.length > 1 && (
                                        <ListSubheader component="div" className={classes.subHeader}>
                                            <Typography variant="overline" >
                                                {link.label}
                                            </Typography>
                                        </ListSubheader>
                                    )}>
                                    {link.options.map(option => {
                                        return (
                                            <ListItem button key={option.id} id={option.id} onClick={() => handleNavigation(option.href)}>
                                                <ListItemText inset={link.options.length > 1} primary={option.label} />
                                            </ListItem>
                                        );
                                    })}
                                </List>
                            )
                        })}
                    </List>
                    <div className={classes.grow} />
                </Toolbar>
            </AppBar>
        </footer>
    );
}


export default Footer;