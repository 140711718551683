import React from "react";
import { useParams } from "react-router-dom";
import { NAVIGATION_LINKS } from "../../const";

export default function Wrapper(props) {
    const { children } = props;
    let { section = NAVIGATION_LINKS.HOME } = useParams();
    React.useEffect(() => {
        const element = document.getElementById(section);
        const y = element.getBoundingClientRect().top + window.pageYOffset + -100;
        window.scrollTo({top: y, behavior: 'smooth'});
    }, [section]);
    return (
        <React.Fragment>
            {children}
        </React.Fragment>
    );
}
