import { Avatar, Divider, Grid, Hidden, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import { NAVIGATION_LINKS } from "../../const/navigation";
import { Fade, Flip } from "react-reveal";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import LanguageIcon from '@material-ui/icons/Language';
import DescriptionIcon from '@material-ui/icons/Description';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import LinearScaleIcon from '@material-ui/icons/LinearScale';
import { TimelineDot } from "@material-ui/lab";
import partnertship from "../../assets/images/partnership.jpg";
import banking from "../../assets/images/banking.jpg";



const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        backgroundColor: theme.palette.secondary.main
    },
    sectionsRoot: {
        overflow: "hidden",
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3)
    },
    sectionContent: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    partnertshipImage: {
        width: "100%",
        height: "100%"
    },
    bankingImage:{
        width: "100%",
        height: "80%"
    },
    pageTitle: {
        margin: theme.spacing(3)
    },
    listRoot: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    avatar: {
        marginRight: theme.spacing(1),
        color: theme.palette.secondary.contrastText,
        borderColor: theme.palette.secondary.contrastText
    },
    largeAvatar: {
        width: 300,
        height: 300,
        [theme.breakpoints.down("xs")]: {
            width: 200,
            height: 200,
        }
    },
    divider: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    },
    body: {
        color: theme.palette.secondary.contrastText,
        "& b": {
            color: theme.palette.primary.light
        }
    },
    image: {
        width: 200,
        height: 200,
        shapeOutside: 'circle(50%)',
        marginRight: theme.spacing(1)
    },

}))
export default function Fiscal(props) {
    const classes = useStyles();
    const theme = useTheme();
    const list1 = [
        { icon: <LinearScaleIcon />, label: "Strategic deployment" },
        { icon: <MonetizationOnIcon />, label: "Financial performance" },
        { icon: <TrendingUpIcon />, label: "Business process optimization" },
        { icon: <LanguageIcon />, label: "Digital banking" }
    ];
    const list2 = [
        { icon: <GroupAddIcon />, label: "Find suitable partners" },
        { icon: <DescriptionIcon />, label: "Estimate and prepare the necessary financial documentation, and" },
        { icon: <StarHalfIcon />, label: "Capitalize the business for review by the interested parties" }
    ]
    return (
        <section id={NAVIGATION_LINKS.FISCAL} className={classes.root}>
            <Flip left>
                <Typography className={classes.pageTitle} variant="h4" color="textPrimary" align="center">Fiscal Management</Typography>
            </Flip>
            <section id="fiscal_banking" className={classes.sectionsRoot}>
                <Grid container justify="flex-end">
                    <Hidden mdUp>
                        <Grid item xs={12} container justify="center">
                            <Fade top>
                                <Avatar alt="Section Banking" src={banking} className={classes.largeAvatar} />
                            </Fade>
                        </Grid>
                    </Hidden>
                    <Hidden smDown>
                        <Grid item md={7}>
                            <Fade left>
                                <img className={classes.bankingImage} src={banking} alt="Section Banking" />
                            </Fade>
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={5} className={classes.sectionContent}>
                        <Fade top delay={200}>
                            <Typography variant="h6" color="textPrimary" style={{ fontWeight: "bolder" }} paragraph={true}>Banking, Finance and Partnerships</Typography>
                        </Fade>
                        <Fade left delay={300}>
                            <Typography variant="body1" className={classes.body} paragraph>The banking and financial services industry has to manage the conflicting needs of everyone. How can you as business make a high return on savings and investments and access cheap lines of credit? How should you deal with banks with increasingly higher costs through regulation, stringent capital adequacy requirements and historically changing environmental interest rates? What are the products, services and prices that succeed in an increasingly complex, commoditized and dynamic market environment? Let us help and guide you with:</Typography>
                        </Fade>
                        <Grid className={classes.listRoot} container spacing={3} justify="flex-end">
                            {list1.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={12 - (index + 1)} container alignItems="center" wrap="nowrap">
                                        <Flip top cascade delay={theme.transitions.duration.enteringScreen * (index + 2)}>
                                            <TimelineDot variant="outlined" className={classes.avatar}  >
                                                {item.icon}
                                            </TimelineDot>
                                            <Typography className={classes.body} variant="body1" color="textSecondary">
                                                {item.label}
                                            </Typography>
                                        </Flip>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                </Grid>
            </section>
            <Divider className={classes.divider} variant="middle" />
            <section id="fiscal_partnership" className={classes.sectionsRoot}>
                <Grid container justify="flex-start">
                    <Hidden mdUp>
                        <Grid item xs={12} container justify="center">
                            <Fade top>
                                <Avatar alt="Section Partnership Photo" src={partnertship} className={classes.largeAvatar} />
                            </Fade>
                        </Grid>
                    </Hidden>
                    <Grid item sm={12} md={6} className={classes.sectionContent}>
                        <Fade left>
                            <Typography className={classes.body} variant="body1" paragraph><b>A business requires tremendous time investment.</b> Especially a successful practice, requires significant attention, often at the sacrifice of your personal time. Partnerships are one way to help mitigate the strain a successful practice can have on your personal life. Professional partnerships aren't always easy to negotiate, establish, and maintain. We can help:</Typography>
                        </Fade>
                        <Grid className={classes.listRoot} container spacing={3} justify="flex-end">
                            {list2.map((item, index) => {
                                return (
                                    <Grid key={index} item xs={12} sm={12 - (index + 1)} container alignItems="center" wrap="nowrap">
                                        <Flip top cascade delay={theme.transitions.duration.enteringScreen * (index + 2)}>
                                            <TimelineDot variant="outlined" className={classes.avatar}>
                                                {item.icon}
                                            </TimelineDot>
                                            <Typography className={classes.body} variant="body1" color="textSecondary">
                                                {item.label}
                                            </Typography>
                                        </Flip>
                                    </Grid>
                                )
                            })}
                        </Grid>
                    </Grid>
                    <Hidden smDown>
                        <Grid item sm={6}>
                            <Fade right>
                                <img className={classes.partnertshipImage} src={partnertship} alt="Section Partnership" />
                            </Fade>
                        </Grid>
                    </Hidden>
                </Grid>
            </section>
        </section>
    )
}