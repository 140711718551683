import { makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles((theme) => ({
    parallax: {
        /* The image used */



        /* Create the parallax scrolling effect */
        backgroundAttachment: 'fixed',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: theme.spacing(1),
        position: "relative",
        [theme.breakpoints.down('xs')]: {
            backgroundAttachment: 'unset',
        }
    }
}));

export default function ParallaxImage(props) {
    const { image, children, height = 300 } = props;
    const classes = useStyles();
    return (
        <div className={classes.parallax} style={{ backgroundImage: `url(${image}`, minHeight: height }}>
            {children}
        </div>

    )
}