import React from "react";
import './App.css';
import {
  Switch,
  Route,
  BrowserRouter
} from "react-router-dom";
import Home from "./pages/Home/Home";
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
// import Services from "./pages/Services/Services";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import Wrapper from "./components/Wrapper/Wrapper";
import Marketing from "./pages/Marketing/Marketing";
import Fiscal from "./pages/Fiscal/Fiscal";
import Consulting from "./pages/Consulting/Consulting";
import ContactUs from "./pages/ContactUs/ContactUs";

let _theme = require("./assets/theme.json");
const theme = createMuiTheme(_theme);
const page = (
  <Wrapper>
    <Home></Home>
    <Marketing></Marketing>
    <Fiscal></Fiscal>
    <Consulting></Consulting>
    <ContactUs></ContactUs>
  </Wrapper>
)
function App() {
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Header></Header>
        <Switch>
          <Route path="/:section">
            {page}
          </Route>
          <Route exact path="/">
            {page}
          </Route>
        </Switch>
        <Footer></Footer>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
