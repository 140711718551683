import { Avatar, Container, Grid, makeStyles, Typography, useTheme } from "@material-ui/core";
import React from "react";
import profit from "../../assets/images/profit.jpg";
import sales from "../../assets/images/sales.jpg";
import product from "../../assets/images/product.jpg";

import { Fade } from 'react-reveal';
import { NAVIGATION_LINKS } from "../../const";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingTop: theme.spacing(2)
    },
    optionRoot: {
        padding: `0 ${theme.spacing(2)}px`
    },
    option: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3)
    },
    largeAvatar: {
        width: 100,
        height: 100,
        float: 'left',
        marginRight: theme.spacing(1),
        shapeOutside: 'circle(50%)',
    },
}));
export default function Marketing(props) {
    const classes = useStyles();
    const theme = useTheme();
    const options = [
        {
            id: "productDevelopment",
            title: "Product Development",
            body: "Want to develop or bring a new product to market? Let us streamline the process.  Place you in contact with the right labs to create your vision. Create a marketing plan to help realize your goals.",
            icon: product
        },
        {
            id: "salesTraining",
            title: "Sales training",
            body: "Want to develop or bring a new product to market? Let us streamline the process.  Place you in contact with the right labs to create your vision. Create a marketing plan to help realize your goals.",
            icon: sales
        },
        {
            id: "profitIncreases",
            title: "Profit Increases",
            body: "Looking for to increase profit and lower expenses? Let us evaluate your business! We can increase your revenue and cut cost without changing or effecting your core business. Get back to doing what you do best. We will handle the rest...",
            icon: profit
        }
    ]
    return (
        <section id={NAVIGATION_LINKS.MARKETING} className={classes.root}>
            <Fade top>
                <Typography variant="h4" color="textPrimary" align="center">Marketing and Implementation</Typography>
            </Fade>
            <Container maxWidth="md">
                <Grid container spacing={3} justify="flex-end" className={classes.optionRoot}>
                    {options.map((option, index) => {
                        return (
                            <Grid item key={option.id} xs={12} sm={12 - index} className={classes.option}>
                                <Fade cascade top delay={theme.transitions.duration.enteringScreen * (index + 2)}>
                                    <Avatar alt={option.title} src={option.icon} className={classes.largeAvatar} />
                                    <Typography variant="h6" color="textPrimary">{option.title}</Typography>
                                    <Typography variant="body2">{option.body}</Typography>
                                </Fade>
                            </Grid>
                        )
                    })}
                </Grid>
            </Container>
        </section >
    );
}