import { Button, CircularProgress, Container, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
import React from "react";
import { EMAIL_SUBJECT,EMAIL_BODY, NAVIGATION_LINKS, SEND_EMAIL } from "../../const";
import contactus_xl from "../../assets/images/contact_xl.jpg";
import contactus_lg from "../../assets/images/contact_lg.jpg";
import contactus_sm from "../../assets/images/contact_sm.jpg";
import SendIcon from '@material-ui/icons/Send';
import {httpServices} from "../../helpers";
const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: 300,
        paddingBottom: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {   
            backgroundImage: `url(${contactus_sm})`,
        },
        [theme.breakpoints.between('sm','xl')]: {   
            backgroundImage: `url(${contactus_lg})`,
        },
        [theme.breakpoints.up('xl')]: {   
            backgroundImage: `url(${contactus_xl})`,
        },
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center"
    },
    textField: {
        '& .MuiOutlinedInput-root': {
            color: theme.palette.common.white,
            '& fieldset': {
                borderColor: theme.palette.secondary.main
            },
            '&:hover fieldset': {
                borderColor: theme.palette.secondary.main
            }
        },
        '& .MuiFormLabel-root': {
            color: theme.palette.common.white
        }
    },
    confirmationMessage: {
        color: theme.palette.common.white,
        '& b' : {
            color: theme.palette.secondary.main
        }
    }
}));
export default function ContactUs(props) {
    const classes = useStyles();
    const [contactForm, setContactForm] = React.useState([
        { name: "Email", value: "", multiline: false, validate: true, func: "validateEmail", isValid: true, errorMessage: "Please enter a valid email." },
        { name: "Message", value: "", multiline: true, validate: true, func: "noEmptyValues", isValid: true, errorMessage: "Please provide us with a small message." }
    ]);
    const [submitting, setSubmitting] = React.useState(false);
    const [submitted, setSubmitted] = React.useState(false);
    React.useEffect(()=> {
        if (localStorage.getItem("submitted")){
            setSubmitted(true);
        }
    },[submitted]);
    const handleFormChange = (index, event) => {
        const { value } = event.target;
        let temp = Object.assign([], contactForm);
        temp[index].value = value;
        if (!temp[index].isValid) {
            temp[index].isValid = validateField(temp[index].func, temp[index].value);
        }
        setContactForm(temp);
    }
    const handleSubmit = () => {
        if (validateForm()) {
            setSubmitting(true);
            httpServices.post(SEND_EMAIL, { emailSubject:EMAIL_SUBJECT, emailBody: EMAIL_BODY(contactForm) })
            .then(
                response => {
                    setSubmitting(true);
                    setSubmitted(true);
                    localStorage.setItem("submitted",true);
                },
                error => {
                    setSubmitting(false);
                    setSubmitted(false);
                }
            );
        }
    }
    const validateEmail = (email) => {
        if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email)) {
            return true
        }
        return false;
    }
    const noEmptyValues = (value) => {
        if (value) {
            return true;
        }
        return false;
    }
    const validateField = (func, value) => {
        let isFieldValid = true;
        switch (func) {
            case "validateEmail":
                isFieldValid = isFieldValid && validateEmail(value);
                break;
            case "noEmptyValues":
                isFieldValid = isFieldValid && noEmptyValues(value);
                break;
            default:
                isFieldValid = isFieldValid && true;
                break;
        }
        return isFieldValid;
    }
    const validateForm = () => {
        let validForm = true;
        let temp = Object.assign([], contactForm)
        temp.forEach(field => {
            if (field.validate) {
                let isFieldValid = validateField(field.func, field.value);;
                field.isValid = isFieldValid;
                validForm = validForm && isFieldValid;
            }
        });
        setContactForm(temp);
        return validForm;
    }
    return (
        <section id={NAVIGATION_LINKS.CONTACT_US} className={classes.root}>
            <Container>
                <Grid container spacing={2} justify="flex-end">
                    {!submitted ?
                        (
                            <Grid item sm={12} md={6} container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="h5" color="secondary" align="center" style={{ fontWeight: "bolder" }}>
                                        Contact Us
                                    </Typography>
                                </Grid>
                                {contactForm.map((field, index) => {
                                    return (
                                        <Grid item xs={12} key={index} >
                                            <TextField color="secondary"
                                                className={classes.textField}
                                                value={field.value}
                                                label={field.name}
                                                name={field.name}
                                                variant="outlined"
                                                multiline={field.multiline}
                                                fullWidth
                                                rows={5}
                                                onChange={(event) => handleFormChange(index, event)}
                                                helperText={field.isValid ? field.placeHolderMessage || "" : field.errorMessage}
                                                error={!field.isValid}
                                                disabled={submitting} />
                                        </Grid>
                                    );
                                })}
                                <Grid item xs={12} container justify="flex-end">
                                    <Button disabled={submitting} color="primary" variant="contained" endIcon={<SendIcon />}
                                        onClick={handleSubmit}>
                                        {submitting ? <CircularProgress color="primary"/> : "Send"}
                                    </Button>
                                </Grid>
                            </Grid>
                        ) :
                        (
                            <Grid item sm={12} md={6}
                                container
                                direction="column"
                                justify="center"
                                alignItems="center"
                                style={{height:300}}>
                                <Grid item xs={12} container direction="column" justify="center">
                                    <Typography className={classes.confirmationMessage}  variant="body1">
                                        <b>Thank you for contacting us.</b> You will receive an email from us shortly.
                                    </Typography>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>

            </Container>
        </section>
    )
}