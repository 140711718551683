export const NAVIGATION_LINKS = {
    HOME: "home",
    SERVICES: "services",
    MARKETING: "marketing-and-implementation",
    FISCAL: "fiscal-management",
    CONSULTATION: "consultation",
    FINANCING: "financing",
    CONTACT_US: "contact-us"
}

export const NAVIGATION_LINKS_LIST = [
    {
        id: "services",
        label: "Services",
        options: [
            {
                label: "Marketing and Implementation", href: `/${NAVIGATION_LINKS.MARKETING}`, id: NAVIGATION_LINKS.MARKETING
            },
            {
                label: "Fiscal Management", href: `/${NAVIGATION_LINKS.FISCAL}`, id: NAVIGATION_LINKS.FISCAL
            },
            {
                label: "Consultation", href: `/${NAVIGATION_LINKS.CONSULTATION}`, id: NAVIGATION_LINKS.CONSULTATION
            }]
    },
    {
        id: "contact-us",
        label: "Contact Us",
        options: [
            {
                label: "Contact Us", href: `/${NAVIGATION_LINKS.CONTACT_US}`, id: NAVIGATION_LINKS.CONTACT_US
            }
        ]
    }
];