import { ButtonBase, Grid, makeStyles, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import React from "react";
import ParallaxImage from "../../components/Parallax/Parallax";
import { NAVIGATION_LINKS } from "../../const";
import consulting from "../../assets/images/consulting.jpg";
import channel from "../../assets/images/channel.jpg";
import pricing from "../../assets/images/pricing.jpg";
import { Fade } from "react-reveal";
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
const useStyles = makeStyles((theme) => ({
    root: {
        overflow:"hidden"
    },
    container: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2)
    },
    divider: {
        margin: theme.spacing(1),
    },
    sectionRoot: {
        position: "relative"
    },
    sectionImage: {
        width: "100%",
        position: "absolute",
        bottom: 0,
        zIndex: -1,
        right: 0,
        height: "50%"
    },
    sectionBody: {
        height: "100%",
        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2)
    },
    sectionWrapper: {
        height: "100%",
        overflow: "auto",
        padding:theme.spacing(3)
    },
    sectionButton: {
        margin: theme.spacing(1)
    },
    bold: {
        color: theme.palette.text.primary
    }
}))
export default function Consulting(props) {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));
    const sections = [
        {
            title: "Channel strategy",
            image: channel,
            body: `Make the most of your channels to market by being in the right places in the optimal way.
            An adroit strategy is essential to winning in a rapidly evolving, digitizing channel landscape. 
            We can help you develop this optimal strategy through an understanding of the customers’ journey and their likes and dislikes, combined with channel requirements, incentivization levers and cost to serve.
            The outcomes will include greater reach, strategic differentiation between channels and outsized share in growing channels — all translating to more revenue and profit.
        ` },
        {
            title: "Pricing strategy",
            image: pricing,
            body: `Maximize overall profit while increasing margins and/or market share.
         Getting pricing right is one of the most critical commercial decisions for any business. 
         It can lead to significant increases in top-line results; the bottom-line effect is even greater. 
         Our approach allows you to develop the optimal initial pricing framework and sales incentives and to see ongoing benefits from an analytical pricing framework informed by customers, market conditions and the competitive environment.
         ` }]
    const [activeSection, setActiveSection] = React.useState(0);
    const handleNavigationSection = (index) => {
        if (index !== activeSection) {
            const parent = document.getElementById("section_body");
            const element = document.getElementById("section_body_" + index);
            const y = element.getBoundingClientRect().top - parent.getBoundingClientRect().y;
            parent.scrollTo({ top: y, behavior: 'smooth' });
        }
    }
    const handleScroll = () => {
        const _parent = document.getElementById("section_body");
        const child = document.getElementById("section_body_content_" + activeSection);
        if (_parent && child) {
            const parent = _parent.scrollTop;
            const section = child.getBoundingClientRect().height;
            if (parent >= section + 100 && activeSection + 1 < sections.length) {
                const next = activeSection + 1
                setActiveSection(next);
            } else if (parent - section - 100 <= 0 && activeSection - 1 >= 0) {
                const next = activeSection - 1
                setActiveSection(next);
            }
        }
    }
    return (
        <section id={NAVIGATION_LINKS.CONSULTATION} className={classes.root}>
            <ParallaxImage image={consulting} height={500}>
                <Typography variant={isMobile ? "h3" : "h2"} color="secondary" align="center" style={{ filter: 'drop-shadow(2px 4px 6px black)' }}>
                    Consultation
                </Typography>
            </ParallaxImage>
                <Grid container>
                    <Grid item xs={12} sm={6} container>
                        <Grid item xs={12} sm={11} className={classes.sectionWrapper}>
                            <Fade top cascade>
                                <Typography variant="body1" paragraph align="justify">
                                    Your business is our priority, no matter the challenges or obstacles you may encounter.
                                    Our partnership marries our specialized capabilities with industry experience to help our clients address their toughest trials.
                                </Typography>
                                <Typography variant="body1" paragraph align="justify">
                                    Our experts take the time to understand your organization and culture to deliver tangible results through a hands-on partnership.
                                    We focus on “working ourselves out of a job” by building your internal talent and processes so that your organization
                                    is agile yet resilient, efficient yet innovative.
                                </Typography>
                                <Typography variant="body1" paragraph align="justify">
                                    Now more than ever, <b className={classes.bold} style={{ marginRight: 5 }}>human capital management is one of the most important drivers of organizational performance.</b>
                                    By focusing on the fundamentals of people strategy—leadership, culture, talent, reskilling, and HR—companies can emerge stronger, more agile, more innovative, and better able to respond to an ever-changing environment.
                                </Typography>
                            </Fade>
                        </Grid>
                    </Grid>
                    <Grid className={classes.sectionRoot} item xs={12} sm={6} container>
                        <Grid item xs={12} sm={4} container direction={isMobile ? "row" : "column"} justify="center">
                            {sections.map((section, index) => {
                                return (
                                    <Grid key={"selector_" + index} item>
                                        <Fade top cascade>
                                            <ButtonBase className={classes.sectionButton} onClick={() => handleNavigationSection(index)}>
                                                {index === activeSection ? <RadioButtonCheckedIcon color="primary" /> : <RadioButtonUncheckedIcon color="primary" />}
                                                <Typography variant="caption">{section.title}</Typography>
                                            </ButtonBase>
                                        </Fade>
                                    </Grid>
                                )
                            })}
                        </Grid>
                        <Grid id="section_body" item xs={12} sm={8} className={classes.sectionWrapper} onScroll={handleScroll}>
                            {sections.map((section, index) => {
                                return (
                                    <div key={"section_body_" + index} id={"section_body_" + index} className={classes.sectionBody}>
                                        <div id={"section_body_content_" + index}>
                                            <Fade top opposite appear
                                                when={index === activeSection}
                                            >
                                                <Typography variant="h6" color="primary" align="center">
                                                    {section.title}
                                                </Typography>
                                            </Fade>
                                            <Fade bottom opposite appear
                                                when={index === activeSection}
                                            >
                                                <Typography variant="body2" paragraph>
                                                    <b className={classes.bold} style={{ marginRight: 5 }}>{section.body.substr(0, section.body.indexOf("."))}.</b>
                                                    {section.body.substr(section.body.indexOf(".") + 1)}
                                                </Typography>
                                            </Fade>
                                        </div>
                                    </div>
                                )
                            })}
                        </Grid>
                        <Fade  opposite appear delay={500}
                            when={sections[activeSection].image === channel} unmountOnExit mountOnEnter>
                            <img className={classes.sectionImage} src={channel} alt="Channel Section background" />
                        </Fade>
                        <Fade  opposite appear delay={500}
                            when={sections[activeSection].image === pricing} unmountOnExit mountOnEnter>
                            <img className={classes.sectionImage} src={pricing} alt="Pricing Section background" />
                        </Fade>
                    </Grid>
                </Grid>
        </section>
    )
}